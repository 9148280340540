@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-byzis2-JoyListItemButton-root.Mui-selected {
  background-color: #f13f41 !important;
}

.css-18vf268 .MuiListItemButton-root:hover {
  background-color: #f13f41 !important;
}

/* .MuiSheet-root MuiSheet-variantPlain MuiSheet-colorNeutral css-1a3o9af-JoySheet-root{
  width: 100vh;
  
} */

@media print {
  body {
    background-color: white !important;
    color: black !important;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 5px;
  }

  .no-print {
    display: none !important; /* Hides elements like buttons during printing */
  }
}

